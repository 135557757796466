import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ContractTypes } from '@draftkings/dk-data-layer';
import { IDataIndex, BooleanIndex, Noop } from '@draftkings/widgets-core';
import {
    BetslipSelectionStatus,
    ILivePageRetriever,
    IParser,
    ParsedEvent,
    ParsedSection,
    ParsedSubTab,
    ParserOptions
} from '../../contracts';
import { parseEvent } from '../helpers/parseEvent';
import { LocalizationAPI, disposer, getDefaultValue, pointsFormatBuilder } from '@draftkings/sportsbook-common-utils';
import {
    ICell,
    IComponent_Map,
    MarketLabelProps,
    MarketWithSelections,
    SelectionHandlerIDs
} from '@draftkings/component-builder';
import {
    FeatureFlags,
    Logos,
    OddsStyles,
    ProcessSelectionPayload,
    SBMessageBus,
    TrackEventFunction
} from '@draftkings/sportsbook-common-contracts';
import { SELECTION_TOGGLE_FALLBACK_TIMEOUT, TRACK_EVENT_NAME } from '../../helpers/constants';
import { LivePageWidgetConfig, NavLinkProps } from '../../../types/window';

type LivePageParserMobx =
    | 'events'
    | 'loadingSectionIds'
    | 'tabs'
    | 'tabId'
    | 'subTabs'
    | 'loadData'
    | 'setTabId'
    | 'setSubTabId'
    | 'resetSubTabs'
    | 'setSectionId'
    | 'removeSectionId'
    | 'resetSectionIds'
    | 'sections'
    | 'oddsStyle'
    | 'collapsedEventIds'
    | 'toggleIsExpanded'
    | 'toggleSelections'
    | 'removeSelections'
    | 'addSelections'
    | 'betslipSelections'
    | 'showOverlay'
    | 'subTabComponentId'
    | 'subTabId'
    | 'sportSeoIdentifier'
    | 'replaceSelectedSelections';

export const FILTER_BY_TIME = 'BY_TIME';
export const groupByFilter = {
    210: FILTER_BY_TIME
};

type ReplacedSelection = Omit<ContractTypes.Selection, 'replacedSelectionId'> & {
    replacedSelectionId: string;
};

export class LivePageParser implements IParser {
    private retriever: ILivePageRetriever;
    private localizationLib: LocalizationAPI;
    private logos: Logos;
    private staticS3Host: string;
    private oddsStyle: OddsStyles;
    private trackEvent: TrackEventFunction;
    private featureFlags: FeatureFlags;
    private loadingSelectionIndex: IDataIndex<string, boolean>;
    private selectedSelectionIndex: IDataIndex<string, boolean>;
    private collapsedEventIds: Set<string>;
    private messageBus: SBMessageBus;
    private casesPerStatus: Record<Exclude<BetslipSelectionStatus, 'Removing'>, (id: string) => void>;
    private toggledSelectionsFallbackHandler: Map<string, () => void>;
    private isMobile: () => boolean;
    private onMobileToggleSelection: () => void;
    private source: LivePageWidgetConfig['source'];
    private renderNavLink: (props: NavLinkProps) => JSX.Element;
    private replacedSelectionDisposer: Noop;
    betslipSelections: Map<string, BetslipSelectionStatus>;
    showOverlay: boolean;
    private processSelectionMap: Record<ProcessSelectionPayload['status'], (s: ProcessSelectionPayload) => void>;

    constructor(options: ParserOptions) {
        this.retriever = options.retriever;
        this.localizationLib = options.localizationLib;
        this.logos = options.logos;
        this.staticS3Host = options.staticS3Host;
        this.oddsStyle = options.oddsStyle;
        this.collapsedEventIds = new Set();
        this.trackEvent = options.trackEvent;
        this.featureFlags = options.featureFlags;
        this.betslipSelections = this.parseBetslipSelection(options.betslipSelections);
        this.messageBus = options.messageBus;
        this.toggledSelectionsFallbackHandler = new Map();
        this.showOverlay = false;
        this.isMobile = options.isMobile;
        this.onMobileToggleSelection = options.onMobileToggleSelection;
        this.source = options.source;
        this.renderNavLink = options.renderNavLink;
        this.replacedSelectionDisposer = disposer;
        this.loadingSelectionIndex = new BooleanIndex(
            (selectionId: string) => {
                const status = this.betslipSelections.get(selectionId);
                return status !== 'Added' && status;
            },
            () => true
        );
        this.processSelectionMap = {
            added: this.betslipAddedSelectionsHandler.bind(this),
            removed: this.betslipRemovedSelectionsHandler.bind(this)
        };

        this.selectedSelectionIndex = new BooleanIndex(
            (selectionId: string) => {
                const status = this.betslipSelections.get(selectionId);
                return status === 'Added' && status;
            },
            () => true
        );
        this.casesPerStatus = {
            Adding: (id) => {
                this.betslipSelections.set(id, 'Added');
            },
            Added: (id) => {
                this.betslipSelections.delete(id);
            }
        };
        makeObservable<typeof this, LivePageParserMobx>(this, {
            events: computed,
            loadingSectionIds: computed,
            tabs: computed,
            tabId: computed,
            subTabs: computed,
            sections: computed,
            loadData: action,
            setTabId: action,
            setSubTabId: action,
            resetSubTabs: action,
            setSectionId: action,
            removeSectionId: action,
            resetSectionIds: action,
            oddsStyle: observable,
            collapsedEventIds: observable,
            toggleIsExpanded: action.bound,
            toggleSelections: action.bound,
            removeSelections: action,
            addSelections: action,
            betslipSelections: observable,
            showOverlay: observable,
            subTabComponentId: computed,
            subTabId: computed,
            sportSeoIdentifier: computed,
            replaceSelectedSelections: action
        });
    }

    get sportSeoIdentifier(): string {
        if (this.retriever.tabId) {
            return getDefaultValue(
                this.retriever.data.tabs.get(this.retriever.tabId)?.associatedData.seoIdentifier,
                ''
            );
        }

        return '';
    }

    get subTabComponentId(): number | undefined {
        if (this.retriever.subTabId) {
            return getDefaultValue(this.retriever.data.subtabs.get(this.retriever.subTabId)?.componentId, undefined);
        }

        return undefined;
    }

    get loadingSectionIds(): string[] {
        return this.retriever.loadingSectionIds;
    }

    setTabId = (tabId: string) => {
        this.retriever.setTabId(tabId);
        this.collapsedEventIds.clear();
    };

    get tabId() {
        return this.retriever.tabId;
    }

    get subTabId() {
        return this.retriever.subTabId;
    }

    get tabs(): ContractTypes.Tab[] {
        return [...this.retriever.data.tabs.values()];
    }

    setSubTabId = (subTabId: string) => {
        this.retriever.setSubTabId(subTabId);
        this.collapsedEventIds.clear();
    };

    get subTabs(): ParsedSubTab[] {
        const subTabs = [...this.retriever.data.subtabs.values()];

        if (!subTabs.length) {
            return [];
        }

        const parsedSubTabs = subTabs.map((subtab) => ({
            ...subtab,
            isSelected: subtab.id === this.retriever.subTabId
        }));

        const hasSelectedSubTab = parsedSubTabs.some((subTab) => subTab.isSelected);

        if (!hasSelectedSubTab) {
            parsedSubTabs[0].isSelected = true;
        }

        return parsedSubTabs;
    }

    resetSubTabs = () => {
        this.retriever.resetSubTabs();
    };

    get sections(): ParsedSection[] {
        if (!this.retriever.sectionIds.length && this.retriever.isResetOfSectionIds) {
            this.retriever.isResetOfSectionIds = false;
            return [];
        }

        return [...this.retriever.data.sections.values()].map((section) => ({
            ...section,
            isExpanded: this.retriever.sectionIds.includes(section.id)
        }));
    }

    setSectionId = (sectionId: string) => {
        this.retriever.setSectionId(sectionId);
    };

    removeSectionId = (sectionId: string) => {
        this.retriever.removeSectionId(sectionId);
    };

    resetSectionIds = () => {
        this.retriever.resetSectionIds();
    };

    loadData = () => {
        this.retriever.loadData();
    };

    toggleIsExpanded(eventId: string) {
        this.collapsedEventIds.has(eventId)
            ? this.collapsedEventIds.delete(eventId)
            : this.collapsedEventIds.add(eventId);
    }

    addSelections = (data: SelectionHandlerIDs): void => {
        this.messageBus.emit('add_selection_to_betslip', {
            providerOutcomeId: data.selectionId,
            source: this.source
        });
    };

    removeSelections = (data: SelectionHandlerIDs): void => {
        this.messageBus.emit('remove_selection', {
            providerOutcomeId: data.selectionId
        });
    };

    handleSelectionToggleFallback(selectionId: string, status: BetslipSelectionStatus | undefined, eventId: string) {
        const timeoutId = setTimeout(() => {
            if (status) {
                this.betslipSelections.set(selectionId, status);
            } else {
                this.betslipSelections.delete(selectionId);
            }

            this.toggledSelectionsFallbackHandler.delete(selectionId);
            this.showOverlay = false;
            this.trackEvent('SELECTION_TOGGLE_TIMED_OUT', {
                eventId,
                selectionId,
                pageName: 'LIVE_PAGE',
                widget: 'LIVE_PAGE_WIDGET'
            });
        }, SELECTION_TOGGLE_FALLBACK_TIMEOUT);

        this.toggledSelectionsFallbackHandler.set(selectionId, () => clearTimeout(timeoutId));
    }

    toggleSelections = (data: SelectionHandlerIDs): void => {
        if (this.isMobile()) {
            this.onMobileToggleSelection();
            return;
        }

        const { selectionId, eventId } = data;
        const status = this.betslipSelections.get(selectionId);
        const selection = this.retriever.data.selections.get(selectionId);

        if (!selection) {
            return;
        }

        if (status) {
            this.betslipSelections.set(selectionId, 'Removing');
            this.removeSelections(data);
        } else {
            this.betslipSelections.set(selectionId, 'Adding');
            this.addSelections(data);
        }

        this.showOverlay = true;
        this.handleSelectionToggleFallback(selectionId, status, eventId);
    };

    private filterMarketsByLeagueId(
        leagueId: string,
        markets: Map<string, ContractTypes.Market>
    ): Map<string, ContractTypes.Market> {
        const filteredMarkets = new Map<string, ContractTypes.Market>();

        markets.forEach((market) => {
            if (market.leagueId === leagueId) {
                filteredMarkets.set(market.id, market);
            }
        });

        return filteredMarkets;
    }

    private groupSelectionsToMarketsByEventId = (
        markets: Map<string, ContractTypes.Market>,
        selections: Map<string, ContractTypes.Selection>,
        leagueId: string
    ): Record<string, MarketWithSelections[]> => {
        const selectionsByMarketId = new Map<string, Map<string, ContractTypes.Selection>>();
        const marketsByLeagueId = this.filterMarketsByLeagueId(leagueId, markets);

        selections.forEach((selection, selectionId) => {
            if (marketsByLeagueId.has(selection.marketId)) {
                const marketSelections = getDefaultValue(selectionsByMarketId.get(selection.marketId), new Map());
                marketSelections.set(selectionId, selection);
                selectionsByMarketId.set(selection.marketId, marketSelections);
            }
        });

        return Array.from(marketsByLeagueId.values()).reduce((acc, market) => {
            const marketSelections = getDefaultValue(
                selectionsByMarketId.get(market.id),
                new Map<string, ContractTypes.Selection>()
            );
            const marketWithSelections = {
                ...market,
                selections: marketSelections
            };

            if (market.eventId) {
                if (!acc[market.eventId]) {
                    acc[market.eventId] = [];
                }

                acc[market.eventId].push(marketWithSelections);
            }

            return acc;
        }, {});
    };

    private get localization() {
        return { formatPoints: pointsFormatBuilder(this.localizationLib) };
    }

    private getParsedEvents(subcategoryId: string, componentId: number): ParsedEvent[] {
        return [...this.retriever.data.events.values()].map((event) =>
            parseEvent({
                event,
                leagueName: getDefaultValue(this.retriever.data.sections.get(event.leagueId)?.name, ''),
                marketsWithSelectionsByEventId: this.groupSelectionsToMarketsByEventId(
                    this.retriever.data.markets,
                    this.retriever.data.selections,
                    event.leagueId
                ),
                isTeamSwap: !!this.retriever.data.sections.get(event.leagueId)?.isTeamSwap,
                localization: this.localization,
                logosMap: this.logos[event.leagueId],
                staticS3Host: this.staticS3Host,
                oddsStyle: this.oddsStyle,
                loadingSelectionIndex: this.loadingSelectionIndex,
                selectedSelectionIndex: this.selectedSelectionIndex,
                collapsedEventIds: this.collapsedEventIds,
                featureFlags: this.featureFlags,
                componentId,
                subcategoryId: subcategoryId,
                logos: this.logos,
                onSelectionClick: (selectionIds) => this.toggleSelections(selectionIds),
                logError: (error) => this.trackEvent(TRACK_EVENT_NAME.LIVE_PAGE_WIDGET_ERROR, error),
                renderNavLink: this.renderNavLink
            })
        );
    }

    private getSubTab = (subTabId: string) => {
        if (subTabId) {
            return [...this.retriever.data.subtabs.values()].find((subTab) => subTab.id === subTabId);
        }
    };

    private groupEventsBySection = (
        events: ParsedEvent[],
        componentId: number
    ): Map<string, Map<string, ParsedEvent[]>> => {
        const groupedEventsBySection = new Map<string, Map<string, ParsedEvent[]>>();
        const sectionIdSet = new Set(this.retriever.sectionIds);

        events.forEach((event) => {
            if (sectionIdSet.has(event.leagueId)) {
                this.addEventToSection(groupedEventsBySection, event.leagueId, event, componentId);
            }
        });

        if (groupByFilter[componentId]) {
            this.processSectionEvents(groupedEventsBySection);
        }

        return groupedEventsBySection;
    };

    private addEventToSection = (
        groupedEventsBySection: Map<string, Map<string, ParsedEvent[]>>,
        sectionId: string,
        event: ParsedEvent,
        componentId: number
    ) => {
        if (!groupedEventsBySection.has(sectionId)) {
            groupedEventsBySection.set(sectionId, new Map<string, ParsedEvent[]>());
        }

        const sectionEvents = groupedEventsBySection.get(sectionId);
        if (sectionEvents) {
            const groupByProperty =
                groupByFilter[componentId] === FILTER_BY_TIME ? event.startEventDate.split('T')[0] : event.id;
            if (!sectionEvents.has(groupByProperty)) {
                sectionEvents.set(groupByProperty, []);
            }
            sectionEvents.get(groupByProperty)?.push(event);
        }
    };

    private processSectionEvents = (groupedEventsBySection: Map<string, Map<string, ParsedEvent[]>>) => {
        groupedEventsBySection.forEach((sectionEvents) => {
            sectionEvents.forEach((events) => {
                const labels = this.extractLabels(events);

                if (events[0].headerData) {
                    events[0].headerData = Object.assign({}, events[0].headerData, { rightSide: labels });
                }
            });
        });
    };

    private extractLabels = (events: ParsedEvent[]): string[] => {
        const labelCells: ICell<MarketLabelProps, IComponent_Map>[] = [];

        events.forEach((event, eventIndex) => {
            event.parsedMarkets.marketsCells.forEach((marketCell) => {
                marketCell.cells.forEach((cell, cellIndex) => {
                    if (cell.type === 'Label') {
                        const shouldUpdateLabelCell =
                            eventIndex === 0 ||
                            ('label' in cell.props &&
                                cell.props.label !== '' &&
                                (!labelCells[cellIndex] || labelCells[cellIndex]?.props.label === ''));

                        if (shouldUpdateLabelCell) {
                            labelCells[cellIndex] = cell as ICell<MarketLabelProps, IComponent_Map>;
                        }
                        marketCell.cells = marketCell.cells.filter((c) => c.id !== cell.id);
                    }
                });
            });
        });

        return labelCells.map((label) => label.props.label);
    };

    get events(): Map<string, Map<string, ParsedEvent[]>> {
        const selectedSubTab = this.getSubTab(this.retriever.subTabId || '');

        if (!selectedSubTab) {
            return new Map();
        }

        const componentId = selectedSubTab.componentId;
        const subcategoryId = selectedSubTab.id;

        const parsedEventsArray = this.getParsedEvents(subcategoryId, componentId);
        const groupedEventsBySection = this.groupEventsBySection(parsedEventsArray, componentId);

        return groupedEventsBySection;
    }

    private parseBetslipSelection(
        betslipSelectionsData: LivePageWidgetConfig['betslipSelections']
    ): Map<string, BetslipSelectionStatus> {
        return new Map(betslipSelectionsData?.regularSelections.map((s) => [s.id, 'Added']));
    }

    private replaceSelectedSelections = (selections: ReplacedSelection[]) => {
        selections.forEach((s) => {
            this.betslipSelections.delete(s.replacedSelectionId);
            this.betslipSelections.set(s.id, 'Added');
        });
    };

    setOddsStyle = (oddsStyle: OddsStyles) => {
        this.oddsStyle = oddsStyle;
    };

    cancelSelectionToggleFallback(selectionId: string) {
        const cancelToggleSelectionFallback = this.toggledSelectionsFallbackHandler.get(selectionId);
        cancelToggleSelectionFallback?.();
        this.toggledSelectionsFallbackHandler.delete(selectionId);
    }

    private processSelections(data: ProcessSelectionPayload[]): void {
        data.forEach((s) => {
            this.processSelectionMap[s.status]?.(s);
        });
    }

    betslipAddedSelectionsHandler(s: ProcessSelectionPayload): void {
        const selectionId = s.selectionId;

        if (s.success) {
            this.casesPerStatus['Adding'](selectionId);
        } else {
            this.casesPerStatus['Added'](selectionId);
        }

        this.cancelSelectionToggleFallback(selectionId);
        this.showOverlay = false;
    }

    betslipRemovedSelectionsHandler(s: ProcessSelectionPayload): void {
        const selectionId = s.selectionId;

        if (s.success) {
            this.casesPerStatus['Added'](selectionId);
        } else {
            this.casesPerStatus['Adding'](selectionId);
        }

        this.cancelSelectionToggleFallback(selectionId);
        this.showOverlay = false;
    }

    activate() {
        this.messageBus.on('odds_style_change', this.setOddsStyle, this);
        this.messageBus.on('process_selections', this.processSelections, this);
        this.replacedSelectionDisposer = reaction(
            () =>
                [...this.retriever.data.selections.values()].filter(
                    (s): s is ReplacedSelection =>
                        !!s.replacedSelectionId && this.betslipSelections.has(s.replacedSelectionId)
                ),
            this.replaceSelectedSelections
        );
    }

    deactivate() {
        this.messageBus.off('odds_style_change', this.setOddsStyle);
        this.messageBus.off('process_selections', this.processSelections);
        this.collapsedEventIds.clear();
        this.replacedSelectionDisposer();
    }
}
