/**
 * Copied deepMerge function from sb-helper lib with following changes:
 * 1. Skipped "clone original" step to decrease memory consumption.
 * 2. Before assigning a property added check on equality.
 * @param original Object to update
 * @param update Object with new values (and properties)
 * @return Merged object
 */
export function eventDeepMerge(original, update) {
    if (!update) {
        return;
    }

    Object.keys(update).forEach((prop) => {
        // For current flows we are skipping participants updates as no need to support it.
        if (prop === 'participants' || prop === 'scoreCards') {
            return;
        }

        if (isObjectCase(original, update, prop) && !Array.isArray(update[prop])) {
            eventDeepMerge(original[prop], update[prop]);
        } else if (!primitiveEqual(update[prop], original[prop])) {
            original[prop] = update[prop];
        }
    });

    return original;
}

export function marketDeepMerge(original, update) {
    if (!update) {
        return;
    }

    Object.keys(update).forEach((prop) => {
        // In the short-term market sorting solution, we should not update 'sortOrder'.
        // It is used to maintain the order of the markets, and we need the initial 'sortOrder' for the remove/add of micro markets.
        // This approach will be deprecated once the backend implements the medium-term sorting solution, which will handle sorting during add and update operations.
        if (prop === 'sortOrder') {
            return;
        }

        if (isObjectCase(original, update, prop) && !Array.isArray(update[prop])) {
            marketDeepMerge(original[prop], update[prop]);
        } else if (!primitiveEqual(update[prop], original[prop])) {
            original[prop] = update[prop];
        }
    });

    return original;
}

export function selectionDeepMerge(original, update) {
    if (!update) {
        return;
    }

    Object.keys(update).forEach((prop) => {
        if (isObjectCase(original, update, prop)) {
            selectionDeepMerge(original[prop], update[prop]);
        } else if (!primitiveEqual(update[prop], original[prop])) {
            original[prop] = update[prop];
        }
    });

    return original;
}

function isObjectCase(original, update, prop: string) {
    return (
        typeof update[prop] === 'object' &&
        !(update[prop] instanceof Date) &&
        Object.prototype.hasOwnProperty.call(original, prop) &&
        !!original[prop]
    );
}

/**
 * Based on deepEqual from "deep-is" package.
 * Only primitive values checks here.
 * Added some changes:
 * 1. Skipped: Treat +0 and -0 as not equal - not-possible/not-important in our scenarios
 * 2. Skipped: objEquiv check - object case is covered in deepMerge.
 * 3. Strict comparison returns true only. Lenient should return false.
 * @param actual value to check.
 * @param expected expected value.
 */
function primitiveEqual(actual, expected) {
    if (actual === expected) {
        return true;
    }

    if (actual instanceof Date && expected instanceof Date) {
        return actual.getTime() === expected.getTime();
    }

    if (isNumberNaN(actual)) {
        return isNumberNaN(expected);
    }

    // Otherwise, including lenient equality (1 == "1") treats as false.
    return false;
}

function isNumberNaN(value) {
    // NaN === NaN -> false
    return typeof value === 'number' && value !== value;
}
