import { ToggleSGPBadge } from '@draftkings/component-builder';
import './index.scss';

function MarketLabel({ label }) {
    return (
        <div data-testid="market-label" className="lp-market__label lp-market__label--text-center">
            <p className="lp-market__label--truncate-strings">{label}</p>
        </div>
    );
}

type MarketHeaderProps = {
    headerData: {
        title: string;
        rightSide?: string[];
        renderSGPNavLink?: (props: { children: React.ReactNode }) => JSX.Element;
        renderEventNavLink?: (props: { children: React.ReactNode }) => JSX.Element;
    };
    tags?: string[];
};

export function MarketHeader({
    headerData: { title, rightSide, renderSGPNavLink, renderEventNavLink: EventNavLink },
    tags = []
}: MarketHeaderProps) {
    const isStaticRightHeader = Array.isArray(rightSide);
    const rightSideTitle = isStaticRightHeader
        ? rightSide?.map((label, index) => <MarketLabel label={label} key={index} />)
        : null;

    return (
        <div
            data-testid={isStaticRightHeader ? 'market-template' : undefined}
            className={isStaticRightHeader ? 'lp-market__template lp-market__template--4-columns' : undefined}
        >
            <div className="lp-title__wrapper">
                {renderSGPNavLink && <ToggleSGPBadge tags={tags} renderSGPNavLink={renderSGPNavLink} />}
                {EventNavLink ? (
                    <div className="lp-title__nav-link">
                        <EventNavLink>
                            <p>{title}</p>
                        </EventNavLink>
                    </div>
                ) : (
                    <p>{title}</p>
                )}
            </div>
            {rightSideTitle}
        </div>
    );
}
