import React from 'react';
import { NavLinkWrapperProps } from '../../contracts';

import './index.scss';

export type SectionHeaderProps = {
    title: string;
    seoIdentifier: string;
    renderLeagueNavLink: (props: NavLinkWrapperProps) => JSX.Element;
};

export const SectionHeader: React.FC<SectionHeaderProps> = React.memo(function SectionHeader(props) {
    const { title, seoIdentifier, renderLeagueNavLink: LeagueNavLink } = props;

    return (
        <LeagueNavLink seoIdentifier={seoIdentifier}>
            <h2 className="lp-section-header" data-testid="section-header">
                <div>{title}</div>
            </h2>
        </LeagueNavLink>
    );
});
