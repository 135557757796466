import { action, makeObservable, observable, runInAction } from 'mobx';
import { throttle } from 'throttle-debounce';
import { ContractTypes, LiveEventsRequestResolver } from '@draftkings/dk-data-layer';
import { DataCondition, BaseRetriever, ICondition, IConditionManager } from '@draftkings/widgets-core';
import { SportsbookPushResponse } from '@draftkings/dk-data-layer/lib-esm/contracts';
import { delay, getDefaultValue, isErrorType } from '@draftkings/sportsbook-common-utils';
import {
    AddPayload,
    ConditionKeys,
    ExtendedMarket,
    ILivePageRetriever,
    RemovePayload,
    RetrieverOptions,
    SportsbookData,
    UpdatePayload
} from '../../contracts';
import { formatWildcardResponseData } from '../helpers';
import { ProductConfig, SBMessageBus, TrackEventFunction } from '@draftkings/sportsbook-common-contracts';
import { TRACK_EVENT_NAME } from '../../helpers/constants';
import { eventDeepMerge, marketDeepMerge, selectionDeepMerge } from '../helpers/deepMеrge';
import { isAbortError } from '@draftkings/sportsbook-common-utils';

export type LivePageRetrieverMobx =
    | 'loadData'
    | '_tabId'
    | '_subTabId'
    | '_loadingSectionIds'
    | 'setTabId'
    | 'setSubTabId'
    | 'resetSubTabs'
    | 'sectionIds'
    | 'setSectionId'
    | 'removeSectionId'
    | 'resetSectionIds'
    | 'onErrorCallback'
    | 'onDataCallback'
    | 'add'
    | 'change'
    | 'remove'
    | 'onDelayedMarketRemoval'
    | 'removeDelayedMarketSelections'
    | 'addDelayedRemovalMarketSelections'
    | 'updateSelectionStorage'
    | 'updateSelections'
    | 'setMarketData';

export class LivePageRetriever
    extends BaseRetriever<ContractTypes.SubscriptionPartial, SportsbookData>
    implements ILivePageRetriever
{
    private initialData: boolean;
    private liveEventsRequestResolver: LiveEventsRequestResolver;
    private SportsbookLeague: ContractTypes.SportsbookLeagueConstructor;
    private trackEvent: TrackEventFunction;
    private condition: IConditionManager<typeof ConditionKeys> & ICondition;
    private serverData: SportsbookData | null;
    private _tabId?: string;
    private _subTabId?: string;
    private currentOpenSectionId: string;
    private subscriptionDisposers: Map<string, () => void> = new Map();
    private _loadingSectionIds: string[];
    private delayedRemovalMarketSelections: Map<string, string[]>;
    private delayedRemovalMarketHandlers: Map<string, () => number | undefined>;
    private delayedAddMarkets: Map<string, ContractTypes.Market>;
    private selectionsStorage: Map<string, ContractTypes.SelectionUpdate>;
    private productConfig: ProductConfig;
    private applySelectionsChanges: () => void;
    private messageBus: SBMessageBus;
    sectionIds: string[];
    isResetOfSectionIds: boolean;

    constructor(options: RetrieverOptions) {
        super({
            ...options,
            onSuspend: () => options.liveEventsRequestResolver.cancel()
        });
        this.initialData = options.productConfig.isLongshotInitialDataEnabled;
        this.liveEventsRequestResolver = options.liveEventsRequestResolver;
        this.SportsbookLeague = options.SportsbookLeague;
        this.condition = options.condition;
        this.trackEvent = options.trackEvent;
        this.serverData = options.serverData;
        this._tabId = options.tabId;
        this.delayedRemovalMarketSelections = new Map();
        this.delayedRemovalMarketHandlers = new Map();
        this.delayedAddMarkets = new Map();
        this.selectionsStorage = new Map();
        this.productConfig = options.productConfig;
        this.messageBus = options.messageBus;
        this.applySelectionsChanges = options.productConfig.livePageWidgetConfig.throttleTimeout
            ? throttle(options.productConfig.livePageWidgetConfig.throttleTimeout, this.updateSelections)
            : this.updateSelections;
        this.onError = (err) => {
            const isError = isErrorType(err);

            if (this.data.events.size && isError) {
                this.condition.set('LivePageRetriever', DataCondition.EMPTY);
            } else if (!isAbortError(err)) {
                this.condition.set('LivePageRetriever', DataCondition.ERROR);
            }

            this.trackEvent(TRACK_EVENT_NAME.LIVE_PAGE_WIDGET_ERROR, {
                statusCode: 'GetLiveDataError',
                description: err instanceof Error ? err.message : JSON.stringify(err),
                tabId: this.tabId,
                subTabId: this.subTabId,
                sectionIds: this.sectionIds,
                widgetVersion: APP_VERSION
            });
        };
        this.currentOpenSectionId = options.currentOpenSectionId;
        this.sectionIds = [];
        this._loadingSectionIds = [];
        this.isResetOfSectionIds = false;
        makeObservable<ILivePageRetriever, LivePageRetrieverMobx>(this, {
            loadData: action,
            setTabId: action,
            setSubTabId: action,
            resetSubTabs: action,
            setSectionId: action,
            removeSectionId: action,
            resetSectionIds: action,
            _tabId: observable,
            _subTabId: observable,
            _loadingSectionIds: observable,
            sectionIds: observable,
            onErrorCallback: action,
            onDataCallback: action,
            add: action,
            change: action,
            remove: action,
            onDelayedMarketRemoval: action,
            removeDelayedMarketSelections: action,
            addDelayedRemovalMarketSelections: action,
            updateSelectionStorage: action,
            updateSelections: action,
            setMarketData: action
        });
    }

    get tabId() {
        return this._tabId;
    }

    get subTabId() {
        return this._subTabId;
    }

    get loadingSectionIds() {
        return [...this._loadingSectionIds];
    }

    setTabId = (tabId: string) => {
        if (tabId === this._tabId) {
            return;
        }

        this._tabId = tabId;
    };

    setSubTabId = (subTabId: string) => {
        if (subTabId === this._subTabId) {
            return;
        }

        this._subTabId = subTabId;
    };

    setSectionId = (sectionId: string) => {
        this.sectionIds.push(sectionId);

        if (this.currentOpenSectionId !== sectionId) {
            this.currentOpenSectionId = sectionId;
        }
    };

    resetSubTabs = () => {
        this._subTabId = undefined;
        this.data.subtabs = new Map();
    };

    removeSectionId = (sectionId: string) => {
        const subscriptionDisposer = this.subscriptionDisposers.get(sectionId);

        if (this.subscriptionDisposer) {
            this.subscriptionDisposer();
        }

        if (subscriptionDisposer) {
            subscriptionDisposer();
            this.subscriptionDisposers.delete(sectionId);
        }

        this.sectionIds = this.sectionIds.filter((id) => id !== sectionId);
    };

    resetSectionIds = () => {
        this.sectionIds.forEach((sectionId) => {
            const subscriptionDisposer = this.subscriptionDisposers.get(sectionId);

            if (this.subscriptionDisposer) {
                this.subscriptionDisposer();
            }

            if (subscriptionDisposer) {
                subscriptionDisposer();
                this.subscriptionDisposers.delete(sectionId);
            }
        });

        this.sectionIds = [];
        this.currentOpenSectionId = '';
        this.isResetOfSectionIds = true;
    };

    loadData = () => {
        this.condition.set('LivePageRetriever', DataCondition.LOADING);
        const currentOpenSectionId = this.currentOpenSectionId;
        this._loadingSectionIds.push(currentOpenSectionId);
        this.getData()
            .then((data) => {
                runInAction(() => (this.data = data));
                this.onLoad(data);
                this.subscriptionDisposers.set(this.currentOpenSectionId, this.subscribe(this.getQuery(data)));
                this._loadingSectionIds = this._loadingSectionIds.filter((id) => id !== currentOpenSectionId);
            })
            .catch((err) => {
                this.onError(err);
                this._loadingSectionIds = [];
            });
    };

    protected subscribe = (params: ContractTypes.SubscriptionPartial) => {
        const event = new this.SportsbookLeague(
            params,
            'event',
            this.initialData,
            this.onDataCallback,
            this.onErrorCallback
        );
        return () => {
            event.deactivate();
        };
    };

    protected getData = async (): Promise<SportsbookData> => {
        this.liveEventsRequestResolver.cancel();
        if (this.serverData) {
            return Promise.resolve(this.serverData).then((data) => {
                this.serverData = null;
                !this.tabId && this.setTabId(getDefaultValue(Array.from(data.tabs.keys())[0], ''));
                !this.subTabId && this.setSubTabId(getDefaultValue(Array.from(data.subtabs.keys())[0], ''));
                !this.sectionIds.length && this.setSectionId(getDefaultValue(Array.from(data.sections.keys())[0], ''));

                this.condition.set('LivePageRetriever', DataCondition.LOADED);
                return data;
            });
        }

        const data = await this.liveEventsRequestResolver.send({
            queryParams: {
                tabId: this.tabId,
                subTabId: this.subTabId,
                sectionIds: this.sectionIds
            }
        });
        if (data?.events.length) {
            !this.tabId && this.setTabId(data.tabs[0].id);
            !this.subTabId && this.setSubTabId(data.subtabs[0].id);
            !this.sectionIds.length && this.setSectionId(data.sections[0].id);

            this.messageBus.emit('on_live_data', {
                response: data
            });

            if (this.currentOpenSectionId === '') {
                this.currentOpenSectionId = data.sections[0].id;
            }

            this.condition.set('LivePageRetriever', DataCondition.LOADED);
            return formatWildcardResponseData(data, this.currentOpenSectionId, this.subTabId || '');
        }

        if (!this.sectionIds.length) {
            this.condition.set('LivePageRetriever', DataCondition.ERROR);
        }

        throw new Error(
            `No data found for tabId: ${this.tabId} subTabId: ${this.subTabId} sectionIds: ${this.sectionIds}`
        );
    };

    protected getQuery = (data: SportsbookData): ContractTypes.SubscriptionPartial => {
        return data.subscriptionPartials;
    };

    private onErrorCallback = (err: unknown) => {
        this.condition.set('LivePageRetriever', DataCondition.ERROR);
        this.trackEvent(TRACK_EVENT_NAME.LIVE_PAGE_WIDGET_ERROR, {
            statusCode: 'SocketConnectionError',
            description: err instanceof Error ? err.message : JSON.stringify(err),
            tabId: this.tabId,
            subTabId: this.subTabId,
            sectionIds: this.sectionIds,
            widgetVersion: APP_VERSION
        });
    };

    private onDataCallback = (response: SportsbookPushResponse) => {
        this.add(response.data.add);
        this.change(response.data.change);
        this.remove(response.data.remove);

        if (this.data.markets.size && this.data.events.size && this.data.selections.size) {
            this.condition.set('LivePageRetriever', DataCondition.LOADED);
        }
    };

    private add = (payload: AddPayload) => {
        payload.events.forEach((event) => {
            const { id } = event;
            const newEvent = { ...event };
            if (event.eventScorecard?.scorecardComponentId) {
                newEvent.eventScorecard = {
                    ...event.eventScorecard,
                    // There is a problem in BE, they return scoreCards on an event update and this will be fixed with the new API version
                    // TODO: Use scorecards instead of scoreCards when the BE fix it
                    scorecards: event.eventScorecard?.scoreCards
                };
            }

            this.data.events.set(id, { ...newEvent });
        });

        payload.markets.forEach((m) => {
            const { correlatedId } = m;
            const delayedMarketCorrelatedId =
                correlatedId && this.delayedRemovalMarketHandlers.has(correlatedId) ? correlatedId : null;
            let currentSortOrder: number | undefined;
            if (delayedMarketCorrelatedId) {
                const hasSelections =
                    !this.delayedRemovalMarketSelections.has(m.id) &&
                    [...this.data.selections.values()].some((s) => s.marketId === m.id);

                if (!hasSelections) {
                    this.delayedAddMarkets.set(m.id, m);
                    return;
                }

                const removeDelayedMarket = this.delayedRemovalMarketHandlers.get(delayedMarketCorrelatedId);
                currentSortOrder = removeDelayedMarket?.();
            }
            this.setMarketData(m, currentSortOrder);
        });

        payload.selections.forEach((s) => {
            const delayedAddMarket = this.delayedAddMarkets.get(s.marketId);
            const { correlatedId } = delayedAddMarket || {};

            if (delayedAddMarket && correlatedId) {
                const removeDelayedMarket = this.delayedRemovalMarketHandlers.get(correlatedId);
                const currentSortOrder = removeDelayedMarket?.();
                this.setMarketData(delayedAddMarket, currentSortOrder);
                this.delayedAddMarkets.delete(delayedAddMarket.id);
            }

            if (this.delayedRemovalMarketSelections.has(s.marketId)) {
                this.removeDelayedMarketSelections(s.marketId);
                const market = this.data.markets.get(s.marketId);

                if (market) {
                    market.isSuspended = false;
                }
            }

            this.data.selections.set(s.id, s);
        });
    };

    private change = (payload: UpdatePayload) => {
        payload.events.forEach((newEvent) => {
            const existEvent = this.data.events.get(newEvent.id);
            if (existEvent) {
                eventDeepMerge(existEvent, {
                    ...newEvent,
                    eventScorecard: {
                        ...newEvent.eventScorecard,
                        // There is a problem in BE, they return scoreCards on an event update and this will be fixed with the new API version
                        // TODO: Use scorecards instead of scoreCards when the BE fix it
                        scorecards: newEvent.eventScorecard?.scoreCards
                    }
                });
            }
        });

        payload.markets.forEach((newMarket) => {
            const oldMarket = this.data.markets.get(newMarket.id);
            if (!oldMarket) {
                return;
            }

            marketDeepMerge(oldMarket, newMarket);
        });

        const selectionsUpdate = new Map<string, ContractTypes.SelectionUpdate>(
            payload.selections.map((s) => [s.id, { ...s }])
        );
        if (selectionsUpdate.size) {
            this.updateSelectionStorage(selectionsUpdate);
            this.applySelectionsChanges();
        }
    };

    private remove = (payload: RemovePayload) => {
        payload.events.forEach((event) => {
            const existingEvent = this.data.events.get(event);
            if (existingEvent) {
                existingEvent.status = 'FINISHED';
            }
        });

        payload.markets.forEach((id) => {
            const market = this.data.markets.get(id);

            if (!market) {
                return;
            }

            const { correlatedId } = market;

            if (market.tags?.includes('MicroMarketDelayRemoval') && correlatedId) {
                market.isSuspended = true;
                this.delayedRemovalMarketHandlers.set(
                    correlatedId,
                    delay(this.productConfig.microMarketsBlinkingConfig.delay, () =>
                        this.onDelayedMarketRemoval(correlatedId, id)
                    )
                );
                return;
            }

            this.data.markets.delete(id);
        });

        payload.selections.forEach((id) => {
            const selection = this.data.selections.get(id);
            const market = selection && this.data.markets.get(selection.marketId);
            const isLastMarketSelection =
                [...this.data.selections.values()].filter((s) => s.marketId === market?.id).length === 1;

            if (market?.tags?.includes('MicroMarketDelayRemoval') && isLastMarketSelection) {
                market.isSuspended = true;
                this.addDelayedRemovalMarketSelections(id, market.id);
                return;
            }

            this.data.selections.delete(id);
            this.selectionsStorage.delete(id);
        });
    };

    private onDelayedMarketRemoval(correlatedId: string, marketId: string) {
        const currentSortOrder = this.data.markets.get(marketId)?.sortOrder;

        this.removeDelayedMarketSelections(marketId);
        this.data.markets.delete(marketId);
        this.delayedRemovalMarketHandlers.delete(correlatedId);

        return currentSortOrder;
    }

    private removeDelayedMarketSelections(marketId: string) {
        const selections = this.delayedRemovalMarketSelections.get(marketId);

        if (selections) {
            selections.forEach((id) => {
                this.data.selections.delete(id);
                this.selectionsStorage.delete(id);
            });
        }

        this.delayedRemovalMarketSelections.delete(marketId);
    }

    private addDelayedRemovalMarketSelections(selectionId: string, marketId: string) {
        if (!this.delayedRemovalMarketSelections.has(marketId)) {
            this.delayedRemovalMarketSelections.set(marketId, []);
        }

        const marketSelections = this.delayedRemovalMarketSelections.get(marketId);
        marketSelections && marketSelections.push(selectionId);
    }

    private updateSelectionStorage(update: Map<string, ContractTypes.SelectionUpdate>) {
        update.forEach((u) => this.selectionsStorage.set(u.id, u));
    }

    private updateSelections() {
        this.selectionsStorage.forEach((newSelections) => {
            const oldSelection = this.data.selections.get(newSelections.id);

            if (!oldSelection) {
                return;
            }

            selectionDeepMerge(oldSelection, newSelections);
        });
        this.selectionsStorage = new Map<string, ContractTypes.SelectionUpdate>();
    }

    private setMarketData(market: ExtendedMarket, currentSortOrder: number | undefined) {
        const isIgnoreSortOrderOnPushTag = market.tags?.includes('IgnoreSortOrderOnPush');
        let marketData = market;

        if (isIgnoreSortOrderOnPushTag) {
            if (currentSortOrder) {
                marketData = { ...market, sortOrder: currentSortOrder };
            } else {
                marketData = { ...market, shouldSkipSorting: true };
            }
        }
        this.data.markets.set(market.id, marketData);
    }
}
