import { getDaySuffix } from '../getDaySuffix';

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const isTomorrow = date.toDateString() === new Date(now.setDate(now.getDate() + 1)).toDateString();

    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);

    if (isToday) {
        return `TODAY`;
    } else if (isTomorrow) {
        return `TOMORROW`;
    } else {
        return `${dayOfWeek} ${month} ${day}${daySuffix}`;
    }
}
