import { makeObservable, computed, action } from 'mobx';
import { DataCondition, ICondition } from '@draftkings/widgets-core';
import { ITabSwitcherController } from '@draftkings/dk-tab-switcher';
import { NavLinkProps as ScoreboardsNavLinkProps } from '@draftkings/scoreboards';
import { IParser, IState, NavLinkWrapperProps, StateOptions } from '../../contracts';
import {
    Logos,
    PlayerData,
    PlayerPageConfig,
    ProductConfig,
    SBMessageBus
} from '@draftkings/sportsbook-common-contracts';
import { encodeLink, generatePlayerURL } from '@draftkings/sportsbook-common-utils';
import { LivePageWidgetConfig, NavLinkProps } from '../../../types/window';

type LivePageStateMobx =
    | 'groupedEventsBySection'
    | 'loadingSectionIds'
    | 'tabId'
    | 'tabs'
    | 'isError'
    | 'isLoaded'
    | 'isLoading'
    | 'onTabClick'
    | 'onSubTabClick'
    | 'onRefreshButtonClick'
    | 'showOverlay'
    | 'sections'
    | 'subTabComponentId';

export class LivePageState implements IState {
    private parser: IParser;
    private tabName?: string;
    private readonly condition: ICondition;
    private messageBus: SBMessageBus;
    private logos: Logos;
    private renderLoadingCard: () => JSX.Element;
    private renderPromoContainer: (tabId: string) => JSX.Element;
    private renderNavLink: (props: NavLinkProps) => JSX.Element;
    private source: LivePageWidgetConfig['source'];
    tabSwitcherController: ITabSwitcherController;
    productConfig: ProductConfig;
    constructor(options: StateOptions) {
        this.parser = options.parser;
        this.tabSwitcherController = options.tabSwitcherController;
        this.tabName = options.tabName;
        this.condition = options.condition;
        this.productConfig = options.productConfig;
        this.messageBus = options.messageBus;
        this.logos = options.logos;
        this.renderLoadingCard = options.renderLoadingCard;
        this.renderPromoContainer = options.renderPromoContainer;
        this.renderNavLink = options.renderNavLink;
        this.source = options.source;

        makeObservable<typeof this, LivePageStateMobx>(this, {
            groupedEventsBySection: computed,
            tabs: computed,
            loadingSectionIds: computed,
            tabId: computed,
            isError: computed,
            isLoaded: computed,
            isLoading: computed,
            onTabClick: action,
            onSubTabClick: action,
            onSectionClick: action,
            onRefreshButtonClick: action,
            showOverlay: computed,
            sections: computed,
            subTabComponentId: computed
        });
    }

    get groupedEventsBySection() {
        return this.parser.events;
    }

    get loadingSectionIds() {
        return this.parser.loadingSectionIds;
    }

    get tabId() {
        return this.parser.tabId;
    }

    get tabs() {
        if (!this.parser.tabs.length) {
            return [];
        }

        const tabs = [...this.parser.tabs].map((tab) => {
            const isSelected = tab.name.toLowerCase() === this.tabName?.toLowerCase();

            return {
                ...tab,
                isSelected,
                subTabs: isSelected ? [...this.parser.subTabs] : []
            };
        });
        const hasSelectedTab = tabs.some((tab) => tab.isSelected);

        return hasSelectedTab
            ? tabs
            : tabs.map((tab, index) => ({
                  ...tab,
                  ...(index === 0 && { isSelected: true, subTabs: [...this.parser.subTabs] })
              }));
    }

    get sections() {
        return [...this.parser.sections.values()];
    }

    get showOverlay() {
        return this.parser.showOverlay;
    }

    get subTabComponentId() {
        return this.parser.subTabComponentId;
    }

    onTabClick = (tabId: string, tabName: string) => {
        if (tabId === this.parser.tabId) {
            return;
        }

        this.messageBus.emit('on_live_page_tab_navigation', {
            subcategory: tabName
        });

        this.parser.resetSubTabs();
        this.parser.resetSectionIds();
        this.tabName = tabName;
        this.parser.setTabId(tabId);
        this.parser.loadData();
    };

    onRefreshButtonClick = () => {
        this.parser.loadData();
    };

    onSubTabClick = (subTabId: string) => {
        if (subTabId === this.parser.subTabId) {
            return;
        }

        this.parser.resetSectionIds();
        this.parser.setSubTabId(subTabId);
        this.parser.loadData();
    };

    onSectionClick = (sectionId: string, isExpanded: boolean) => {
        if (!isExpanded) {
            this.parser.setSectionId(sectionId);
            this.parser.loadData();
        } else {
            this.parser.removeSectionId(sectionId);
        }
    };

    onToggleExpandClick = (eventId: string) => {
        this.parser.toggleIsExpanded(eventId);
    };

    isPlayerPagesEnabled = (leagueId: string): boolean => {
        return this.productConfig.playerPageConfig.isEnabled && this.logos[leagueId]?.EnablePlayerPages === 'true';
    };

    playerPageConfig = (leagueId: string): PlayerPageConfig => {
        return {
            isPlayerImagesEnabled:
                this.productConfig.isPlayerImagesEnabled && this.logos[leagueId]?.EnablePlayerImages === 'true',
            isPlayerPagesEnabled: this.isPlayerPagesEnabled(leagueId)
        };
    };

    getPlayerLink = (data: PlayerData, leagueId: string, leagueName: string) => {
        if (!this.isPlayerPagesEnabled(leagueId)) {
            return '';
        }

        return generatePlayerURL({
            sport: this.parser.sportSeoIdentifier,
            league: leagueName,
            name: data.playerName,
            id: data.playerId,
            productConfig: this.productConfig
        });
    };

    getScoreboardsLink = (props: React.PropsWithChildren<ScoreboardsNavLinkProps>) => {
        return this.renderNavLink({
            ...props,
            url: props.to
        });
    };

    onPlayerLinkClick = (url: string) => {
        this.messageBus.emit('on_player_link_click', {
            url
        });
    };

    formatQueryParam = (name: string) => name.toLowerCase().replace(/\s/g, '-');

    formatSportQueryParam = (sportDisplayName: string) => {
        const linkMatch = sportDisplayName.match(/\b\w+/g);

        if (!linkMatch) {
            return '';
        }

        return encodeLink(linkMatch.join(''));
    };

    formatLeagueQueryParam = (seoIdentifier: string) => encodeLink(this.formatQueryParam(seoIdentifier));

    renderLoadingCardWrapper = () => {
        return this.renderLoadingCard();
    };

    renderPromoContainerWrapper = (tabId: string) => {
        return this.renderPromoContainer(tabId);
    };

    renderLeaguePageNavLink = (props: NavLinkWrapperProps) => {
        const { seoIdentifier, ...restProps } = props;
        const sport = this.formatSportQueryParam(this.parser.sportSeoIdentifier);
        const league = this.formatLeagueQueryParam(seoIdentifier);
        const url = `/leagues/${sport}/${league}`;

        return this.renderNavLink({ ...restProps, url });
    };

    buildTabNavigationUrl(subcategory: string): string {
        if (this.source === 'home') {
            return '';
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (this.parser.sportSeoIdentifier === subcategory) {
            return urlParams.size ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname;
        }

        urlParams.set('category', 'live-in-game');
        urlParams.set('subcategory', encodeLink(subcategory));

        return `${window.location.pathname}?${urlParams.toString()}`;
    }

    activate() {
        this.parser.activate();
    }

    deactivate() {
        this.parser.deactivate();
    }

    get isError() {
        return this.condition.value === DataCondition.ERROR;
    }
    get isLoaded() {
        return this.condition.value === DataCondition.LOADED;
    }
    get isLoading() {
        return this.condition.value === DataCondition.LOADING;
    }
}
