import React from 'react';
import cx from 'classnames';

import './index.scss';

export type SectionCollapsibleArrowProps = {
    isExpanded: boolean;
};

export const SectionCollapsibleArrow: React.FC<SectionCollapsibleArrowProps> = ({ isExpanded }) => {
    const templateBaseClass = 'lp-section-collapsible';
    const arrowIconInnerClass = isExpanded ? 'up' : 'down';

    return (
        <div className={`${templateBaseClass}-arrow-wrapper`} data-testid="section-collapsible-arrow">
            <div className={`${templateBaseClass}-arrow`}>
                <svg
                    role="img"
                    className={cx(
                        `${templateBaseClass}-arrow-inner__${arrowIconInnerClass}`,
                        `${templateBaseClass}-arrow-inner`
                    )}
                    viewBox="0 0 32 32"
                >
                    <path d="M16.032 6.144h-0.032l-14.624 14.656c-0.384 0.384-0.384 0.992 0 1.344l1.504 1.504c0.384 0.384 0.992 0.384 1.344 0l11.776-11.776h0.032l11.776 11.776c0.384 0.384 0.992 0.384 1.344 0l1.504-1.504c0.384-0.384 0.384-0.992 0-1.344l-14.624-14.656z"></path>
                </svg>
            </div>
        </div>
    );
};
