import * as React from 'react';
import { SectionHeader } from '../SectionHeader';
import { SectionCollapsibleArrow } from '../SectionCollapsibleArrow';
import { NavLinkWrapperProps } from '../../contracts';

import './index.scss';

type AnimationProps = {
    duration: number;
    ease: string;
};

type SectionProps = {
    isExpanded: boolean;
    isSectionLoading: boolean;
    title: string;
    seoIdentifier: string;
    children: React.ReactNode;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    renderLoadingCard: () => JSX.Element;
    renderLeagueNavLink: (props: NavLinkWrapperProps) => JSX.Element;
    animationConfig: AnimationProps;
    sectionRef?: React.RefObject<HTMLDivElement>;
    subTabComponentId?: number;
};

export const Section: React.FC<SectionProps> = React.memo(function Section({
    isExpanded,
    isSectionLoading,
    title,
    seoIdentifier,
    children,
    onClick,
    renderLoadingCard,
    renderLeagueNavLink,
    animationConfig,
    sectionRef,
    subTabComponentId
}) {
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const height = contentRef.current?.scrollHeight || 0;

    const contentStyle = React.useMemo(
        () => ({
            gridTemplateRows: `${+isExpanded}fr`,
            transition: `all ${animationConfig.duration}ms ${animationConfig.ease}`
        }),
        [isExpanded, animationConfig]
    );

    return (
        <div data-componentId={subTabComponentId} className="lp-section" ref={sectionRef}>
            <button
                type="button"
                className="lp-collapsible-section"
                onClick={onClick}
                data-testid="collapsible-section"
                aria-expanded={isExpanded}
            >
                <SectionHeader title={title} seoIdentifier={seoIdentifier} renderLeagueNavLink={renderLeagueNavLink} />
                <SectionCollapsibleArrow isExpanded={isExpanded} />
            </button>
            <div
                className="lp-collapsible__content"
                ref={contentRef}
                // TODO: Add cleaner solution here with pure CSS once `calc-size()` is widely supported by browsers.
                style={contentStyle}
            >
                <div className="lp-collapsible__content--inner" style={{ overflow: 'hidden' }}>
                    {isSectionLoading && renderLoadingCard()}
                    {isExpanded && !isSectionLoading ? (
                        children
                    ) : (
                        <div className="lp-collapsible__content--inner-placeholder" style={{ height: `${height}px` }} />
                    )}
                </div>
            </div>
        </div>
    );
});
