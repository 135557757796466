import { getDefaultValue } from '@draftkings/sportsbook-common-utils';
import { SportEvent } from '@draftkings/sportsbook-common-contracts';
import { NavLinkProps } from '../../../../types/window';

export const getRenderNavLink = (
    event: SportEvent,
    renderNavLink: (props: NavLinkProps) => JSX.Element,
    buildNavLink: (eventId: string, seoIdentifier: string) => string
) => {
    return (props: { children: React.ReactNode }) =>
        renderNavLink({
            children: props.children,
            url: buildNavLink(event.id, getDefaultValue(event.seoIdentifier, ''))
        });
};
