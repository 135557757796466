import { ContractTypes, LiveEventsRequestResolver } from '@draftkings/dk-data-layer';
import { BaseRetrieverOptions, IRetriever, IConditionManager, ICondition } from '@draftkings/widgets-core';
import { ProductConfig, SBMessageBus, TrackEventFunction } from '@draftkings/sportsbook-common-contracts';

export type SportsbookData = {
    tabs: Map<string, ContractTypes.Tab>;
    subtabs: Map<string, ContractTypes.SubTab>;
    sections: Map<string, ContractTypes.Section>;
    events: Map<string, ContractTypes.SportEvent>;
    markets: Map<string, ContractTypes.Market>;
    selections: Map<string, ContractTypes.Selection>;
    subscriptionPartials: ContractTypes.SubscriptionPartial;
};

export interface ILivePageRetriever extends IRetriever<SportsbookData> {
    tabId?: string;
    subTabId?: string;
    sectionIds: string[];
    loadingSectionIds: string[];
    loadData: () => void;
    setTabId: (tabId: string) => void;
    setSubTabId: (subTabId: string) => void;
    resetSubTabs: () => void;
    setSectionId: (sectionId: string) => void;
    removeSectionId: (sectionId: string) => void;
    resetSectionIds: () => void;
    isResetOfSectionIds: boolean;
}

export type RetrieverOptions = BaseRetrieverOptions<SportsbookData> & {
    SportsbookLeague: ContractTypes.SportsbookLeagueConstructor;
    liveEventsRequestResolver: LiveEventsRequestResolver;
    condition: IConditionManager<typeof ConditionKeys> & ICondition;
    consumerVersion: string;
    serverData: SportsbookData | null;
    messageBus: SBMessageBus;
    productConfig: ProductConfig;
    currentOpenSectionId: string;
    tabId?: string;
    subTabId?: string;
    trackEvent: TrackEventFunction;
};

export type AddPayload = {
    leagues: ContractTypes.League[];
    events: ContractTypes.SportEventAdd[];
    markets: ContractTypes.Market[];
    selections: ContractTypes.Selection[];
    sports: ContractTypes.Sport[];
};

export type UpdatePayload = {
    events: ContractTypes.SportEventUpdate[];
    markets: ContractTypes.MarketUpdate[];
    selections: ContractTypes.SelectionUpdate[];
};

export type RemovePayload = {
    events: string[];
    markets: string[];
    selections: string[];
};

export const ConditionKeys = ['LivePageRetriever'] as const;

export type ExtendedMarket = ContractTypes.Market & { shouldSkipSorting?: boolean };
