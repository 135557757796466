const SUFFIXES = {
    ST: 'ST',
    ND: 'ND',
    RD: 'RD',
    TH: 'TH'
};

export function getDaySuffix(day: number): string {
    if (day % 10 === 1 && day !== 11) return SUFFIXES.ST;
    if (day % 10 === 2 && day !== 12) return SUFFIXES.ND;
    if (day % 10 === 3 && day !== 13) return SUFFIXES.RD;
    return SUFFIXES.TH;
}
