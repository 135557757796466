import { MarketHeader } from '../MarketHeader';
import './index.scss';

type StaticMarketHeaderProps = {
    headerData: {
        title: string;
        rightSide?: string[];
    };
};

export function StaticMarketHeader({ headerData }: StaticMarketHeaderProps) {
    return (
        <div className="lp-static__header">
            <MarketHeader headerData={headerData} />
        </div>
    );
}
