import { ContractTypes } from '@draftkings/dk-data-layer';

export type EventState = {
    minute: number;
    second: number;
    period: string;
    state: string;
};

export const parseLiveGameState = (
    liveGameState: ContractTypes.LiveGameState | undefined,
    state: string
): EventState => {
    const defaultStatus = {
        minute: 0,
        second: 0,
        period: liveGameState?.period || '',
        state
    };

    if (!liveGameState) {
        return defaultStatus;
    }

    if (liveGameState.gameTime && shouldCalculateGameTime(liveGameState.period)) {
        const { gameTime, period = '' } = liveGameState;
        const minute = Math.floor(gameTime / 60);
        const second = gameTime - minute * 60;

        return {
            minute,
            second,
            period,
            state
        };
    }

    return defaultStatus;
};

const shouldCalculateGameTime = (gamePart = ''): boolean =>
    !(gamePart.startsWith('BreakAfter') || gamePart === 'Finished');
