import React from 'react';
import { observer } from 'mobx-react';
import { TabsWrapper, Tab, Content, SubTab, SubTabsWrapper } from '@draftkings/dk-tab-switcher';
import { IState } from '../../contracts';
import { Section } from '../Section/Section';
import { TrackEventFunction } from '@draftkings/sportsbook-common-contracts';
import { DateCellProps, TeamLogoProps } from '@draftkings/scoreboards';
import { Marketboard } from '../Marketboard';
import { LivePageError } from '../LivePageError';
import { marketBoardDict } from '../../helpers';
import cx from 'classnames';

import '@draftkings/scoreboards/style.css';
import '@draftkings/component-builder/style.css';
import '@draftkings/dk-tab-switcher/style.css';
import './index.scss';

type AppProps = {
    consumerVersion: string;
    dataProvider: IState;
    trackEvent: TrackEventFunction;
    getTeamLogo: (props: TeamLogoProps) => JSX.Element;
    getDateCell: (props: DateCellProps) => JSX.Element;
};

class AppComponent extends React.Component<AppProps, IState> {
    private marketboardRef: React.RefObject<HTMLDivElement>;
    constructor(props: AppProps) {
        super(props);
        this.marketboardRef = React.createRef<HTMLDivElement>();
    }

    render() {
        const baseClass = 'lp-live-page-widget';
        return (
            <>
                <section
                    className={cx(baseClass, {
                        [`${baseClass}__overlay`]: this.props.dataProvider.showOverlay
                    })}
                >
                    <TabsWrapper>
                        {this.props.dataProvider.tabs.map((tab) => (
                            <Tab
                                route={tab.id}
                                key={tab.id}
                                controller={this.props.dataProvider.tabSwitcherController}
                                isSelectedByDefault={tab.isSelected}
                                onClick={() => this.props.dataProvider.onTabClick(tab.id, tab.name)}
                                navigationUrl={this.props.dataProvider.buildTabNavigationUrl(tab.name)}
                            >
                                {tab.name}
                            </Tab>
                        ))}
                    </TabsWrapper>
                    {this.props.dataProvider.tabs.map((tab) => (
                        <Content route={tab.id} key={tab.id} controller={this.props.dataProvider.tabSwitcherController}>
                            <SubTabsWrapper>
                                {tab.subTabs.map((subtab) => (
                                    <SubTab
                                        route={`${tab.id}/${subtab.id}`}
                                        key={subtab.id}
                                        controller={this.props.dataProvider.tabSwitcherController}
                                        isSelectedByDefault={tab.isSelected && subtab.isSelected}
                                        onClick={() => this.props.dataProvider.onSubTabClick(subtab.id)}
                                        navigationUrl=""
                                    >
                                        <div>{subtab.name}</div>
                                    </SubTab>
                                ))}
                            </SubTabsWrapper>
                        </Content>
                    ))}
                    {this.props.dataProvider.tabId &&
                        this.props.dataProvider.renderPromoContainerWrapper(this.props.dataProvider.tabId)}
                    {this.props.dataProvider.isError && (
                        <LivePageError
                            title={marketBoardDict.onLoadErrorTitle}
                            text={marketBoardDict.onLoadErrorText}
                            buttonText="Refresh"
                            onClick={this.props.dataProvider.onRefreshButtonClick}
                        />
                    )}
                    {this.props.dataProvider.showOverlay && <div className="lp-live-page-widget__overlay" />}
                    {!this.props.dataProvider.isError &&
                        this.props.dataProvider.sections.map((section, sectionIndex) => {
                            const { seoIdentifier } = section.associatedData;
                            const isFirstSection = sectionIndex === 0;
                            return (
                                <Section
                                    sectionRef={isFirstSection ? this.marketboardRef : undefined}
                                    key={section.id}
                                    title={section.name}
                                    seoIdentifier={seoIdentifier}
                                    isExpanded={section.isExpanded}
                                    onClick={() =>
                                        this.props.dataProvider.onSectionClick(section.id, section.isExpanded)
                                    }
                                    isSectionLoading={this.props.dataProvider.loadingSectionIds.includes(section.id)}
                                    renderLoadingCard={this.props.dataProvider.renderLoadingCardWrapper}
                                    renderLeagueNavLink={this.props.dataProvider.renderLeaguePageNavLink}
                                    subTabComponentId={this.props.dataProvider.subTabComponentId}
                                    animationConfig={{
                                        duration: 300,
                                        ease: 'ease-in-out'
                                    }}
                                >
                                    {Array.from(
                                        this.props.dataProvider.groupedEventsBySection.get(section.id)?.values() || []
                                    )
                                        .flatMap((eventsMap) => {
                                            const filteredEvents = eventsMap.filter(
                                                (event) => event.status !== 'FINISHED'
                                            );
                                            return filteredEvents.length > 0 ? [filteredEvents] : [];
                                        })
                                        .map((eventsMap) => (
                                            <Marketboard
                                                key={eventsMap[0].id}
                                                section={section}
                                                eventsMap={eventsMap}
                                                consumerVersion={this.props.consumerVersion}
                                                productConfig={this.props.dataProvider.productConfig}
                                                marketboardRef={this.marketboardRef}
                                                trackEvent={this.props.trackEvent}
                                                playerPageConfig={this.props.dataProvider.playerPageConfig}
                                                getPlayerLink={this.props.dataProvider.getPlayerLink}
                                                onPlayerLinkClick={this.props.dataProvider.onPlayerLinkClick}
                                                getTeamLogo={this.props.getTeamLogo}
                                                onToggleExpandClick={this.props.dataProvider.onToggleExpandClick}
                                                getDateCell={this.props.getDateCell}
                                                subTabComponentId={this.props.dataProvider.subTabComponentId}
                                                getScoreboardsLink={this.props.dataProvider.getScoreboardsLink}
                                            />
                                        ))}
                                </Section>
                            );
                        })}
                </section>
            </>
        );
    }
}

export const App = observer(AppComponent);
