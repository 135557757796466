import { SportEvent } from '@draftkings/sportsbook-common-contracts';

export function getTeamsScore(
    event: SportEvent,
    skipParseScoreboards: boolean
): { firstTeamScore: string; secondTeamScore: string } | undefined {
    if (skipParseScoreboards) {
        const firstTeamScore = event.eventScorecard?.mainScorecard?.firstTeamScore;
        const secondTeamScore = event.eventScorecard?.mainScorecard?.secondTeamScore;

        if (!firstTeamScore || !secondTeamScore) {
            return undefined;
        }

        return {
            firstTeamScore,
            secondTeamScore
        };
    }
    return undefined;
}
