import React from 'react';
import cx from 'classnames';
import { Collapsible, MarketMapping, TeamLogoProps } from '@draftkings/component-builder';
import {
    PlayerData,
    PlayerPageConfig,
    ProductConfig,
    TrackEventFunction
} from '@draftkings/sportsbook-common-contracts';
import { DateCellProps, Scoreboard, NavLinkProps as ScoreboardsNavLinkProps } from '@draftkings/scoreboards';
import { ParsedEvent, ParsedSection } from '../../contracts';
import { StaticMarketHeader } from '../StaticMarketHeader';
import { MarketHeader } from '../MarketHeader';
import { isParlayCard } from '../../helpers';
import './index.scss';

type MarketboardProps = {
    section: ParsedSection;
    eventsMap: ParsedEvent[];
    consumerVersion: string;
    productConfig: ProductConfig;
    marketboardRef: React.RefObject<HTMLDivElement>;
    subTabComponentId: number | undefined;
    trackEvent: TrackEventFunction;
    playerPageConfig: (leagueId: string) => PlayerPageConfig;
    getPlayerLink: (data: PlayerData, leagueId: string, leagueName: string) => string;
    onPlayerLinkClick: (url: string) => void;
    getTeamLogo: (props: TeamLogoProps) => JSX.Element;
    onToggleExpandClick: (eventId: string) => void;
    getDateCell: (props: DateCellProps) => JSX.Element;
    getScoreboardsLink: (props: React.PropsWithChildren<ScoreboardsNavLinkProps>) => JSX.Element;
};

export const Marketboard: React.FC<MarketboardProps> = ({
    section,
    eventsMap,
    consumerVersion,
    productConfig,
    marketboardRef,
    subTabComponentId,
    trackEvent,
    playerPageConfig,
    getPlayerLink,
    onPlayerLinkClick,
    getTeamLogo,
    onToggleExpandClick,
    getDateCell,
    getScoreboardsLink
}) => {
    return (
        <div className="lp-marketboard-layout" key={eventsMap[0].id}>
            {subTabComponentId === undefined ? null : !isParlayCard(subTabComponentId) ? (
                <Collapsible
                    trigger={{
                        data: {
                            title: <MarketHeader headerData={eventsMap[0].headerData} tags={eventsMap[0]?.tags} />
                        }
                    }}
                    content={eventsMap.map((event) =>
                        event.parsedMarkets.marketsCells.map((marketCell, index) => (
                            <MarketMapping
                                consumerVersion={consumerVersion}
                                widgetVersion={APP_VERSION}
                                {...marketCell}
                                key={`market-${marketCell.key}`}
                                productConfig={productConfig}
                                trackEvent={trackEvent}
                                playerPageConfig={playerPageConfig(section.id)}
                                getPlayerLink={(data) => getPlayerLink(data, section.id, section.name)}
                                onPlayerLinkClick={onPlayerLinkClick}
                                marketboardRef={marketboardRef}
                                getTeamLogo={getTeamLogo}
                                scoreboardComponent={
                                    event.scoreboardData && index === 0 ? (
                                        <Scoreboard
                                            {...event.scoreboardData}
                                            shouldSkipNavigation={false}
                                            eventUrl={`/event/${event.id}`}
                                            getDateCell={getDateCell}
                                            getTeamLogo={getTeamLogo}
                                            getNavLink={getScoreboardsLink}
                                        />
                                    ) : undefined
                                }
                            />
                        ))
                    )}
                    isExpanded={eventsMap[0].parsedMarkets.isExpanded}
                    contentId={eventsMap[0].id}
                    contentType={'event'}
                    onToggleExpandClick={() => {
                        onToggleExpandClick(eventsMap[0].id);
                    }}
                    animationConfig={{
                        duration: 300,
                        ease: 'ease-in-out'
                    }}
                />
            ) : (
                <div data-testid="static-wrapper">
                    <StaticMarketHeader headerData={eventsMap[0].headerData} />
                    {eventsMap.map((event) => (
                        <div
                            className={cx('lp-static__content', { 'single-content': eventsMap.length === 1 })}
                            key={event.id}
                        >
                            <div className="lp-static__content-inner">
                                {event.parsedMarkets.marketsCells.map((marketCell) => (
                                    <MarketMapping
                                        consumerVersion={consumerVersion}
                                        widgetVersion={APP_VERSION}
                                        {...marketCell}
                                        key={`market-${marketCell.key}`}
                                        productConfig={productConfig}
                                        trackEvent={trackEvent}
                                        playerPageConfig={playerPageConfig(section.id)}
                                        getPlayerLink={(data) => getPlayerLink(data, section.id, section.name)}
                                        onPlayerLinkClick={onPlayerLinkClick}
                                        marketboardRef={marketboardRef}
                                        getTeamLogo={getTeamLogo}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
