import { BaseWidget, Condition } from '@draftkings/widgets-core';
import { ContractTypes, LiveEventsRequestResolver } from '@draftkings/dk-data-layer';
import { Controller } from '@draftkings/dk-tab-switcher';
import { App } from '../components/App/App';
import { ILivePageWidgetConfig, LivePageWidgetConfig } from '../../types/window';
import { LivePageRetriever } from '../retriever/LivePageRetriever/LivePageRetriever';
import { LivePageParser } from '../parser/LivePageParser/LivePageParser';
import { LivePageState } from '../state/LivePageState/LivePageState';
import { ConditionKeys, IState } from '../contracts';
import { getServerData } from '../retriever/helpers/getServerData';
import * as localizationLib from '@draftkings/sb-localization-lib';
import { disposer, getDefaultValue } from '@draftkings/sportsbook-common-utils';
import { GLOBAL_CLIENT_FETCH_TIMEOUT } from '../helpers/constants';

export class LivePageWidget extends BaseWidget implements ILivePageWidgetConfig {
    private dataProvider: IState;
    constructor(domId: string, options: LivePageWidgetConfig) {
        super(domId, () => {});
        const condition = new Condition(ConditionKeys);
        const retriever = new LivePageRetriever({
            currentOpenSectionId: options.currentOpenSectionId,
            liveEventsRequestResolver: new LiveEventsRequestResolver({
                baseUrl: options.productConfig.sportsContentViewsBff,
                requestTimeout: getDefaultValue(options.requestTimeout, GLOBAL_CLIENT_FETCH_TIMEOUT),
                consumerMetadata: {
                    clientFeature: 'live-page',
                    clientPage: 'Live',
                    clientVersion: options.consumerVersion,
                    widgetName: 'LivePageWidget',
                    widgetVersion: APP_VERSION
                }
            }),
            SportsbookLeague: options.dkDataLayer.SportsbookLeague,
            defaultData: {
                tabs: new Map<string, ContractTypes.Tab>(),
                subtabs: new Map<string, ContractTypes.SubTab>(),
                sections: new Map<string, ContractTypes.Section>(),
                events: new Map<string, ContractTypes.SportEvent>(),
                markets: new Map<string, ContractTypes.Market>(),
                selections: new Map<string, ContractTypes.Selection>(),
                subscriptionPartials: {
                    entity: '',
                    query: '',
                    includeMarkets: ''
                }
            },
            serverData: getServerData(options.livePageData, options.currentOpenSectionId),
            messageBus: options.messageBus,
            productConfig: options.productConfig,
            condition,
            consumerVersion: options.consumerVersion,
            trackEvent: options.trackEvent,
            tabId: options.tabId
        });
        const isMobileFallback = getDefaultValue(options.isMobile, () => false);
        const parser = new LivePageParser({
            retriever,
            localizationLib,
            logos: options.logos,
            staticS3Host: options.staticS3Host,
            oddsStyle: options.oddsStyle,
            trackEvent: options.trackEvent,
            featureFlags: options.featureFlags,
            betslipSelections: options.betslipSelections,
            messageBus: options.messageBus,
            isMobile: isMobileFallback,
            onMobileToggleSelection: getDefaultValue(options.onMobileToggleSelection, disposer),
            source: options.source,
            renderNavLink: options.renderNavLink
        });
        this.dataProvider = new LivePageState({
            parser,
            tabSwitcherController: new Controller(),
            tabName: options.tabName,
            condition,
            productConfig: options.productConfig,
            messageBus: options.messageBus,
            logos: options.logos,
            renderLoadingCard: options.renderLoadingCard,
            renderPromoContainer: options.renderPromoContainer,
            renderNavLink: options.renderNavLink,
            source: options.source
        });
        this.component = (
            <App
                dataProvider={this.dataProvider}
                consumerVersion={options.consumerVersion}
                trackEvent={options.trackEvent}
                getTeamLogo={options.getTeamLogo}
                getDateCell={options.getDateCell}
            />
        );
    }

    activate() {
        super.activate();
        this.dataProvider.activate();
        return this;
    }

    deactivate() {
        super.deactivate();
        this.dataProvider.deactivate();
        return this;
    }
}
