import { ContractTypes } from '@draftkings/dk-data-layer';
import { IDataIndex, Localization, OddsStyles, SelectionHandlerIDs } from '@draftkings/component-builder';
import { MarketBuilderConfig, MarketWithSelections, marketCellsBuilder } from '@draftkings/component-builder';
import { parseScoreboards } from '@draftkings/scoreboards';
import { ParsedEvent } from '../../../contracts';
import { getDefaultValue } from '@draftkings/sportsbook-common-utils';
import { FeatureFlags, Logos, LogosMap } from '@draftkings/sportsbook-common-contracts';
import { getTeamsScore } from '../getTeamScore';
import { shouldSkipParseScoreboards } from '../skipParseScoreboards';
import { NavLinkProps } from '../../../../types/window';
import { isParlayCard } from '../../../helpers';
import { getPartialParsedInputs } from '../getPartialParsedInputs/getPartialParsedInputs';

export type ParseEventArgs = {
    event: ContractTypes.SportEvent;
    marketsWithSelectionsByEventId: Record<string, MarketWithSelections[]>;
    isTeamSwap: boolean;
    localization: Localization;
    loadingSelectionIndex: IDataIndex<string, boolean>;
    selectedSelectionIndex: IDataIndex<string, boolean>;
    oddsStyle: OddsStyles;
    logosMap: LogosMap;
    leagueName: string;
    staticS3Host: string;
    collapsedEventIds: Set<string>;
    featureFlags: FeatureFlags;
    subcategoryId: string;
    componentId: number;
    logos: Logos;
    onSelectionClick: (selectionIds: SelectionHandlerIDs) => void;
    logError: MarketBuilderConfig['logError'];
    renderNavLink: (props: NavLinkProps) => JSX.Element;
};

export const parseEvent = ({
    event,
    marketsWithSelectionsByEventId,
    isTeamSwap,
    localization,
    oddsStyle,
    logosMap,
    leagueName,
    staticS3Host,
    loadingSelectionIndex,
    selectedSelectionIndex,
    featureFlags,
    subcategoryId,
    componentId,
    logos,
    collapsedEventIds,
    onSelectionClick,
    logError,
    renderNavLink
}: ParseEventArgs): ParsedEvent => {
    const skipParseScoreboards = shouldSkipParseScoreboards(componentId);
    const partialParsedInputs = getPartialParsedInputs({
        event,
        isParlayCard: isParlayCard(componentId),
        renderNavLink
    });

    return {
        ...event,
        leagueName,
        scoreboardData: !skipParseScoreboards
            ? parseScoreboards({
                  event,
                  logosMap,
                  staticS3Host,
                  isTeamSwap
              })
            : undefined,
        parsedMarkets: {
            isExpanded: !collapsedEventIds.has(event.id),
            marketsCells: marketCellsBuilder({
                eventId: event.id,
                eventStatus: event.status,
                markets: marketsWithSelectionsByEventId[event.id] || [],
                categoryId: '',
                subCategoryId: subcategoryId,
                componentId,
                localization,
                loadingSelectionIndex,
                selectedSelectionIndex,
                oddsStyle,
                isTeamSwap,
                participants: getDefaultValue(event.participants?.slice(), []),
                featureFlags,
                consumerPageName: 'LivePage',
                logos,
                staticS3Host,
                logError,
                onSelectionClick,
                teamsScore: getTeamsScore(event, skipParseScoreboards),
                renderEventNavLink: partialParsedInputs?.marketData?.renderEventNavLink,
                renderSGPNavLink: partialParsedInputs?.marketData?.renderSGPNavLink,
                parsedEventState: partialParsedInputs?.marketData?.parsedEventState,
                tags: partialParsedInputs?.marketData?.tags || undefined,
                startEventDate: partialParsedInputs?.marketData?.startEventDate
            })
        },
        headerData: {
            title: partialParsedInputs.headerData.title,
            renderSGPNavLink: partialParsedInputs?.headerData?.renderSGPNavLink,
            renderEventNavLink: partialParsedInputs?.headerData?.renderEventNavLink
        }
    };
};
