import { SportEvent } from '@draftkings/sportsbook-common-contracts';
import { getRenderNavLink } from '../getRenderNavLink';
import { parseLiveGameState } from '../parseLiveGameState';
import { formatDate } from '../formatDate';
import { buildEventNavLink } from '../buildEventNavLink';
import { buildSGPNavLink } from '../buildSGPNavLink';
import { NavLinkProps } from '../../../../types/window';
import { getDefaultValue } from '@draftkings/sportsbook-common-utils';

type PartialParsedInputsType = {
    event: SportEvent;
    isParlayCard: boolean;
    renderNavLink: (props: NavLinkProps) => JSX.Element;
};

export const getPartialParsedInputs = ({ event, isParlayCard, renderNavLink }: PartialParsedInputsType) => {
    if (isParlayCard) {
        return {
            marketData: {
                renderEventNavLink: getRenderNavLink(event, renderNavLink, buildEventNavLink),
                renderSGPNavLink: getRenderNavLink(event, renderNavLink, buildSGPNavLink),
                parsedEventState: parseLiveGameState(event.liveGameState, event.status),
                tags: getDefaultValue(event.tags, []),
                startEventDate: event.startEventDate
            },
            headerData: {
                title: formatDate(event.startEventDate)
            }
        };
    }

    return {
        marketData: {},
        headerData: {
            title: event.name,
            renderEventNavLink: getRenderNavLink(event, renderNavLink, buildEventNavLink),
            renderSGPNavLink: getRenderNavLink(event, renderNavLink, buildSGPNavLink)
        }
    };
};
